const GOOGLE_STORAGE_BASE_URL = 'https://storage.googleapis.com/pp_img/pp_agent'
const GCP_URL = 'https://storage.googleapis.com/pp_agent_mweb/static'

export const ImageUrl = {
  // general
  logo: `${GOOGLE_STORAGE_BASE_URL}/logo.png`,
  logoPP: `${GOOGLE_STORAGE_BASE_URL}/logo-pp.png`,
  shield: `${GOOGLE_STORAGE_BASE_URL}/shield.png`,
  backIcon: `${GOOGLE_STORAGE_BASE_URL}/back.png`,
  editIcon: `${GOOGLE_STORAGE_BASE_URL}/edit-icon.png`,
  homeIcon: `${GOOGLE_STORAGE_BASE_URL}/home.png`,
  list: `${GOOGLE_STORAGE_BASE_URL}/list.png`,
  policiesIcon: `${GOOGLE_STORAGE_BASE_URL}/policies.png`,
  userIcon: `${GOOGLE_STORAGE_BASE_URL}/user-avatar.png`,
  emailIcon: `${GOOGLE_STORAGE_BASE_URL}/email-icon.png`,
  phoneIcon: `${GOOGLE_STORAGE_BASE_URL}/phone-icon.png`,
  walletIcon: `${GOOGLE_STORAGE_BASE_URL}/wallet.png`,
  badgeIcon: `${GOOGLE_STORAGE_BASE_URL}/badge.png`,
  moneyBagIcon: `${GOOGLE_STORAGE_BASE_URL}/money_bag.png`,
  cameraIcon: `${GOOGLE_STORAGE_BASE_URL}/camera.png`,
  // loading
  landingBackground: `${GOOGLE_STORAGE_BASE_URL}/landing-background.png`,
  // register
  registerBackground: `${GOOGLE_STORAGE_BASE_URL}/register-background.png`,
  // otp
  otpBackground: `${GOOGLE_STORAGE_BASE_URL}/otp-bg.png`,
  // dashboard
  motorComingSoon: `${GOOGLE_STORAGE_BASE_URL}/motor-comingsoon.png`,
  gadgetComingSoon: `${GOOGLE_STORAGE_BASE_URL}/gadget-comingsoon.png`,
  vehicleProduct: `${GOOGLE_STORAGE_BASE_URL}/vehicle-product.png`,
  dashboardBackground: `${GOOGLE_STORAGE_BASE_URL}/dashboard-background.png`,
  motorImage: `${GOOGLE_STORAGE_BASE_URL}/motor-image.png`,
  closeIcon: `${GOOGLE_STORAGE_BASE_URL}/close.png`,
  // balance page
  balanceBackground: `${GOOGLE_STORAGE_BASE_URL}/balance-background.png`,
  emptyBox: `${GOOGLE_STORAGE_BASE_URL}/box.png`,
  // profile page
  profileBackground: `${GOOGLE_STORAGE_BASE_URL}/user-detail-background.png`,
  npwpIcon: `${GOOGLE_STORAGE_BASE_URL}/npwp_icon.png`,
  ktpIcon: `${GOOGLE_STORAGE_BASE_URL}/ktp_icon.png`,
  idIcon: `${GOOGLE_STORAGE_BASE_URL}/id_icon.png`,
  // payment page
  paymentSuccessBackground: `${GOOGLE_STORAGE_BASE_URL}/payment-success-background.svg`,
  paymentSuccessIcon: `${GOOGLE_STORAGE_BASE_URL}/payment-success-icon.svg`,
  paymentFailedBackground: `${GOOGLE_STORAGE_BASE_URL}/payment-failed-background.svg`,
  paymentFailedIcon: `${GOOGLE_STORAGE_BASE_URL}/payment-failed-icon.svg`,
  paymentPendingBackground: `${GOOGLE_STORAGE_BASE_URL}/payment-pending-background.svg`,
  paymentPendingIcon: `${GOOGLE_STORAGE_BASE_URL}/payment-pending-icon.svg`,
  paymentSuccessBanner1: `${GOOGLE_STORAGE_BASE_URL}/payment-success-banner-1.png`,
  paymentSuccessBanner2: `${GOOGLE_STORAGE_BASE_URL}/payment-success-banner-2.png`,
  paymentArrowDownIcon: `${GOOGLE_STORAGE_BASE_URL}/arrow_down.svg`,
  paymentArrowGif: `${GOOGLE_STORAGE_BASE_URL}/payment-arrow.gif`,
  // policy page
  policyBackground: `${GOOGLE_STORAGE_BASE_URL}/policyBackground.png`,
  policyCar: `${GOOGLE_STORAGE_BASE_URL}/policy-car.png`,
  shieldIcon: `${GOOGLE_STORAGE_BASE_URL}/shield_icon.png`,
  policyDetailBackground: `${GOOGLE_STORAGE_BASE_URL}/package_detail_background.png`,
  checklistIcon: `${GOOGLE_STORAGE_BASE_URL}/checklist.png`,
  caretDown: `${GOOGLE_STORAGE_BASE_URL}/caret-down-1.png`,
  caretUp: `${GOOGLE_STORAGE_BASE_URL}/caret-up.png`,
  paymentIcon: `${GOOGLE_STORAGE_BASE_URL}/payment_icon.png`,
  // withdrawal page
  bankTransferLogo: `${GOOGLE_STORAGE_BASE_URL}/bank-transfer-logo.png`,
  arrowRight: `${GOOGLE_STORAGE_BASE_URL}/arrow-right.png`,
  withdrawSuccessBackground: `${GOOGLE_STORAGE_BASE_URL}/withdrawSuccessBackground.png`,
  withdrawSuccessIcon: `${GOOGLE_STORAGE_BASE_URL}/withdrawSuccessIcon.png`,
  // application page
  applicationShield: `${GOOGLE_STORAGE_BASE_URL}/application-shield.png`,
  applicationMedal: `${GOOGLE_STORAGE_BASE_URL}/application-medal.png`,
  applicationWallet: `${GOOGLE_STORAGE_BASE_URL}/application-wallet.png`,
  policySuccessBackground: `${GOOGLE_STORAGE_BASE_URL}/policy-success-background.png`,
  tickButtonIcon: `${GOOGLE_STORAGE_BASE_URL}/tick-button-icon.png`,
  editButtonIcon: `${GOOGLE_STORAGE_BASE_URL}/edit-button-icon.png`,

  // gojek driver page
  banner: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/gokek-driver-home-banner-new.png`,
  incomeIcon1: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/income-icon1.png`,
  incomeIcon2: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/income-icon2.png`,
  incomeIcon3: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/income-icon3.png`,
  additionalIncomeIcon1: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/additional-incon-iocn1.png`,
  additionalIncomeIcon2: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/additional-incon-iocn2.png`,
  additionalIncomeIcon3: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/additional-incon-iocn3.png`,
  facilityIcon1: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/facilities-icon1.png`,
  facilityIcon2: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/facilities-icon2.png`,
  facilityIcon3: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/facilities-icon3.png`,
  playStore: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/playstore-icon.png`,
  appleStore: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/apple-store-icon.png`,
  scheduleBanner: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/schedule-mitra-gen-banner.png`,
  scheduleProcess: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/schedule-mitra-gen-calculation-process.png`,
  scheduleTable: `${GOOGLE_STORAGE_BASE_URL}/gojek_driver/schedule-mitra-gen-calculation-table.png`
}

export const ADD_TO_HOME = `${GOOGLE_STORAGE_BASE_URL}/add_home_icon.svg`
export const IOS_ADD_TO_HOME = `${GOOGLE_STORAGE_BASE_URL}/logo_45X45.png`
export const PP_LOGO = `${GOOGLE_STORAGE_BASE_URL}/logo-pp.png`
export const WHITE_PP_LOGO = `${GOOGLE_STORAGE_BASE_URL}/white_pp_logo.png`
export const WELCOME_HOME = `${GOOGLE_STORAGE_BASE_URL}/agent-welcome-1.png`
export const WELCOME_BOTTOM = `${GOOGLE_STORAGE_BASE_URL}/welcome-bottom.png`
export const MITRA_PP_WHITE_LOGO = `${GCP_URL}/ppm-logo-white.png`
export const GET_MONEY_SVG = `${GOOGLE_STORAGE_BASE_URL}/get_money.svg`
export const CALL_HOSPITAL_SVG = `${GOOGLE_STORAGE_BASE_URL}/call_hospital.svg`
export const NOTE_SVG = `${GOOGLE_STORAGE_BASE_URL}/note.svg`
export const WEBSITE_SVG = `${GOOGLE_STORAGE_BASE_URL}/website.svg`
export const PASAR_MITRA_BB = `${GCP_URL}/ppm-logo-black-2x.png`
export const PASAR_MITRA_NEW = `${GCP_URL}/pp-mitra-new-logo.svg`
export const PASAR_MITRA_STACKED = `${GCP_URL}/pasar_polis_mitra_stacked.png`
export const CALENDER = `${GOOGLE_STORAGE_BASE_URL}/calendar_1.svg`
export const SALARY_SVG = `${GOOGLE_STORAGE_BASE_URL}/salary.svg`
export const PHONE_RP = `${GOOGLE_STORAGE_BASE_URL}/phone_rp.svg`
export const THUMB_SVG = `${GOOGLE_STORAGE_BASE_URL}/thumb.svg`
export const TASK_LIST_SVG = `${GOOGLE_STORAGE_BASE_URL}/task.svg`
export const CAMERA_ICON = `${GOOGLE_STORAGE_BASE_URL}/camera.png`
export const VERIFIED_DOC_SVG = `${GOOGLE_STORAGE_BASE_URL}/verified_doc_submit.svg`
export const VERIFY_EMAIL_SUCCESS = `${GOOGLE_STORAGE_BASE_URL}/verify-email-bg-success.png`
export const VERIFY_EMAIL_FAILED = `${GOOGLE_STORAGE_BASE_URL}/verified_email_failed.png`
export const VERIFIED_EMAIL_SVG = `${GOOGLE_STORAGE_BASE_URL}/verify-email-icon.svg`
export const VERIFIED_EMAIL_TIME_PASSED = `${GOOGLE_STORAGE_BASE_URL}/verified-email-time-expired.png`
export const QUOTATION_SUCCESS = `${GOOGLE_STORAGE_BASE_URL}/quotation_success.svg`
export const SEND_CUSTOMER_LINK = `${GOOGLE_STORAGE_BASE_URL}/sent_customer_email.svg`
export const REFERRAL_EARN = `${GOOGLE_STORAGE_BASE_URL}/referral-earn.png`
export const GO_PAY = `${GOOGLE_STORAGE_BASE_URL}/gopay.png`
export const SUN_LIFE = `${GOOGLE_STORAGE_BASE_URL}/sun-life-color-logo.png`
export const ALLIANZ = `${GOOGLE_STORAGE_BASE_URL}/allianz.svg`
export const COVID_SVG = `${GOOGLE_STORAGE_BASE_URL}/covid-19.png`
export const JIWA_PRODUCT = `${GOOGLE_STORAGE_BASE_URL}/sekoci.png`
export const RECOMMENDED = `${GOOGLE_STORAGE_BASE_URL}/recommended-ribbon.svg`
export const COVID_WHATSAPP_SHARE = `${GOOGLE_STORAGE_BASE_URL}/covid-whatsapp-info.jpg`
export const SALES_GUIDE = `${GOOGLE_STORAGE_BASE_URL}/sales-guide.png`
export const GREEN_CHECK_V3 = `${GCP_URL}/Check%20Line.png`
export const TELEPHONEV2 = `${GCP_URL}/Vector.png`
export const AUS = `${GCP_URL}/AUS.png`
export const BRIEFCASE = `${GOOGLE_STORAGE_BASE_URL}/Briefcase.svg`
export const ID_CARD = `${GOOGLE_STORAGE_BASE_URL}/DebitCard.svg`
export const USER_PROFILE = `${GOOGLE_STORAGE_BASE_URL}/UserProfile.svg`
export const SHIELD = `${GOOGLE_STORAGE_BASE_URL}/Shields.svg`
export const STARS = `${GOOGLE_STORAGE_BASE_URL}/Stars.svg`
export const MONEY_BAG_V2 = `${GOOGLE_STORAGE_BASE_URL}/Money_Bags.svg`

// Covid Sales Guide
export const COVID_SALES_LANDING_GUIDE = `${GOOGLE_STORAGE_BASE_URL}/covid-sales-guide-landing.png`
export const COVID_PKG_LIST_GUIDE = `${GOOGLE_STORAGE_BASE_URL}/covid-pkg-list-guide.png`
export const COVID_LINK_SHARING_GUIDE = `${GOOGLE_STORAGE_BASE_URL}/covid-link-sharing-guide.png`
export const COVID_WHATSAPP_SHARING_GUIDE = `${GOOGLE_STORAGE_BASE_URL}/covid-whatsa-sharing-guide.png`
export const COVID_CUSTOMER_PKG_GUIDE = `${GOOGLE_STORAGE_BASE_URL}/customer-pkg-guide.png`
export const COVID_POLICY_HOLDER_GUIDE = `${GOOGLE_STORAGE_BASE_URL}/policy-holder-guide.png`
export const COVID_PAYMENT_GUIDE = `${GOOGLE_STORAGE_BASE_URL}/covid-payment-guide.png`
export const COVID_PAYMENT_SUCCUSS_GUIDE = `${GOOGLE_STORAGE_BASE_URL}/payment-success-guide.png`
export const GOJEK_BG = `${GOOGLE_STORAGE_BASE_URL}/gojek-bg.png`
export const GOJEK_DRIVER = `${GOOGLE_STORAGE_BASE_URL}/gojek-driver-video.jpeg`

// ICONS
export const CLOSE_ICON = `${GOOGLE_STORAGE_BASE_URL}/close.png`
export const ADDON_SHIELD = `${GOOGLE_STORAGE_BASE_URL}/addon_shield.svg`
export const CONFIGURATION = `${GOOGLE_STORAGE_BASE_URL}/configuration.svg`
export const ADD_CLAIM_RP = `${GOOGLE_STORAGE_BASE_URL}/add_claim_rp.svg`
export const TELEPHONE = `${GOOGLE_STORAGE_BASE_URL}/telephone_2.svg`
export const LOCATION = `${GOOGLE_STORAGE_BASE_URL}/location.svg`
export const INFO = `${GOOGLE_STORAGE_BASE_URL}/info.svg`
export const CAR_SVG = `${GOOGLE_STORAGE_BASE_URL}/car-icon.svg`
export const IOS_ADD_ICON = `${GOOGLE_STORAGE_BASE_URL}/ios_add_to_home_icon.svg`
export const AGENT_ICON = `${GOOGLE_STORAGE_BASE_URL}/agent-logo.svg`
export const CUSTOMER_ICON = `${GOOGLE_STORAGE_BASE_URL}/customer.svg`
export const CIRCLE_THUMB_WHITE = `${GOOGLE_STORAGE_BASE_URL}/circle_thumb_white.svg`
export const BANKING_ATM = `${GOOGLE_STORAGE_BASE_URL}/banking_atm.svg`
export const CARD_ATM = `${GOOGLE_STORAGE_BASE_URL}/card_atm.svg`
export const BUSSINESS_MEN = `${GOOGLE_STORAGE_BASE_URL}/businessman.svg`
export const REFER_EARN = `${GOOGLE_STORAGE_BASE_URL}/peer_to_peer.svg`
export const TEAM = `${GOOGLE_STORAGE_BASE_URL}/team.svg`
export const PIN = `${GOOGLE_STORAGE_BASE_URL}/PIN.svg`
export const CORRECT_ICON = `${GOOGLE_STORAGE_BASE_URL}/correct_icon.svg`
export const QUESTION = `${GOOGLE_STORAGE_BASE_URL}/question.svg`
export const REVIEW = `${GOOGLE_STORAGE_BASE_URL}/review.svg`
export const PAPER = `${GOOGLE_STORAGE_BASE_URL}/paper.svg`
export const DANGER = `${GOOGLE_STORAGE_BASE_URL}/danger_sing.svg`
export const PHOTO = `${GOOGLE_STORAGE_BASE_URL}/photo.svg`
export const VERIFIED_BADGE = `${GOOGLE_STORAGE_BASE_URL}/medal_2.svg`
export const TAG = `${GOOGLE_STORAGE_BASE_URL}/tag.svg`
export const SEARCH = `${GOOGLE_STORAGE_BASE_URL}/search.svg`
export const SORT = `${GOOGLE_STORAGE_BASE_URL}/Sort.svg`
export const FAQ_QUESTION = `${GOOGLE_STORAGE_BASE_URL}/faq.svg`
export const CONSULTANT = `${GOOGLE_STORAGE_BASE_URL}/support.svg`
export const NOTIFICATION = `${GOOGLE_STORAGE_BASE_URL}/notification.svg`
export const PLUS = `${GCP_URL}/plus.svg`
export const GOLD_BELL = `${GOOGLE_STORAGE_BASE_URL}/gold_bell.svg`
export const GOLD_TRANSACTION = `${GOOGLE_STORAGE_BASE_URL}/transaction_gold.svg`
export const EMAIL = `${GOOGLE_STORAGE_BASE_URL}/envelope.svg`
export const WHATSAPP = `${GOOGLE_STORAGE_BASE_URL}/whatsapp.svg`
export const EMAIL_ICON = `${GOOGLE_STORAGE_BASE_URL}/email_icon.svg`
export const PKG_NOT_FOUND = `${GOOGLE_STORAGE_BASE_URL}/package_not_found.svg`
export const PKG_NOT_FOUND_CARGO = `${GCP_URL}/ask-cargo-icon.svg`
export const HEALTH_SHIELD = `${GCP_URL}/health-shield.svg`
export const PKG_NOT_FOUND_PROPERTY = `${GCP_URL}/property-pkg-not-found.svg`
export const PAGE_NOT_FOUND = `${GOOGLE_STORAGE_BASE_URL}/page_404.svg`
export const SILVER_STAR = `${GOOGLE_STORAGE_BASE_URL}/silver-star.svg`
export const PLATINUM_STAR = `${GOOGLE_STORAGE_BASE_URL}/platinum-star.svg`
export const GOLD_STAR = `${GOOGLE_STORAGE_BASE_URL}/gold-star.svg`
export const WHATSAPP_GOLD = `${GOOGLE_STORAGE_BASE_URL}/whatsapp-gold.svg`
export const COPY_GOLD = `${GOOGLE_STORAGE_BASE_URL}/copy-gold.svg`
export const SMS_GOLD = `${GOOGLE_STORAGE_BASE_URL}/email_gold_icon.svg`
export const FAX_SVG = `${GOOGLE_STORAGE_BASE_URL}/fax.svg`
export const LETTER_SVG = `${GOOGLE_STORAGE_BASE_URL}/letter.svg`
export const XMAIL_SVG = `${GOOGLE_STORAGE_BASE_URL}/x-mlid.svg`
export const HELP_HOME_ICON = `${GOOGLE_STORAGE_BASE_URL}/help_homepage.svg`
export const HAND = `${GOOGLE_STORAGE_BASE_URL}/hand.svg`
export const COVID_INSURANCE_HELP = `${GOOGLE_STORAGE_BASE_URL}/covid-insurance-help.svg`
export const INPUT_CALENDAR_SVG = `${GOOGLE_STORAGE_BASE_URL}/input-calendar.svg`
export const LIGHT_GOLD_BULB = `${GOOGLE_STORAGE_BASE_URL}/light_bulb_gold.svg`
export const PAID_WATERMARK = `${GCP_URL}/receipt_stamp_v2.svg`
export const NEW_COPY_ICON = `${GOOGLE_STORAGE_BASE_URL}/copy_icon.png`
export const NEW_HOME_ICON = `${GOOGLE_STORAGE_BASE_URL}/home_icon.png`
export const NEW_POLICY_ICON = `${GOOGLE_STORAGE_BASE_URL}/new_icon.png`
export const NEW_SENT_ICON = `${GOOGLE_STORAGE_BASE_URL}/sent_icon.png`
export const NEW_LOGO_PP = `${GOOGLE_STORAGE_BASE_URL}/new_pasarpolis.png`
export const QS_LOGO_PP = `${GOOGLE_STORAGE_BASE_URL}/Top.png`

// login slide images
export const LOGIN_FIRST_SLIDE = `${GOOGLE_STORAGE_BASE_URL}/login-slider-1.png`
export const LOGIN_SECOND_SLIDE = `${GOOGLE_STORAGE_BASE_URL}/login-slider-2.png`

// agents imgs
export const ANDITO = `${GOOGLE_STORAGE_BASE_URL}/andito.png`
export const ANDREW = `${GOOGLE_STORAGE_BASE_URL}/andrew.png`
export const BISMO = `${GOOGLE_STORAGE_BASE_URL}/bismo.png`

// polygons
export const RED_POLYGON = `${GOOGLE_STORAGE_BASE_URL}/red-polygon.png`
export const RED_FULL_POLYGON = `${GOOGLE_STORAGE_BASE_URL}/red-full-polygon.png`
export const YELLOW_SQUARE_POLYGON = `${GOOGLE_STORAGE_BASE_URL}/yellow-square-xl-polygon.png`
export const YELLOW_REACT_POLYGON = `${GOOGLE_STORAGE_BASE_URL}/yellow-react-angle-polygon.png`
export const PLATINUM_POLYGON = `${GOOGLE_STORAGE_BASE_URL}/platinum-polygon.png`
export const SILVER_POLYGON = `${GOOGLE_STORAGE_BASE_URL}/silver-polygon.png`
export const GOLD_POLYGON = `${GOOGLE_STORAGE_BASE_URL}/gold-polygon.png`
export const BRONZE_POLYGON = `${GOOGLE_STORAGE_BASE_URL}/bronze-polygon.png`
export const SILVER_SMALL_POLYGON = `${GCP_URL}/silver-polygon-2.svg`
export const SILVER_RECTANGLE_POLYGON = `${GCP_URL}/silver-rectangle-polygon.svg`

// smiles icon
export const SAD_SMILE = `${GOOGLE_STORAGE_BASE_URL}/sad_smile.svg`

// new path for images
export const STNK = `${GCP_URL}/stnk.png`
export const STNK_MOTOR_CYCLE = `${GCP_URL}/stnk-motor-cycle.jpg`
export const VEHICLE_LEFT_FACE_IMG = `${GCP_URL}/bboga_hl_left.png`
export const VEHICLE_RIGHT_FACE_IMG = `${GCP_URL}/bboga_hl.png`
export const VEHICLE_FACE_IMG = `${GCP_URL}/bbo_gvl_f.png`
export const VEHICLE_DASHBOARD_IMG = `${GCP_URL}/bbo_gtb_a.png`
export const VEHICLE_BACK_IMG = `${GCP_URL}/bbo_gl_em.png`
export const KTP_IMG = `${GCP_URL}/ktp_img.png`
export const NPWP_IMG = `${GCP_URL}/NPWP.png`
export const CERTIFICATE_PREVIEW = `${GCP_URL}/prevoew_certificate.png`

// infographic Img
export const SEKOCI_INFOGRAPIC = `${GCP_URL}/sekoci-infographic.png`
export const SUN_LIFE_NEW_INFOGRAPHIC = `${GCP_URL}/sunlife-infographic-new.jpg`
export const ADIRA_MOTOR_INFOGRAPHIC = `${GCP_URL}/info_graphic_motor_adira.jpg`
export const LIPPO_HCP_INFOGRAPHIC = `${GCP_URL}/lippo-hcp_infographic.png`

// icons
export const PHONE_GOLD = `${GCP_URL}/phone_gold.svg`
export const PAPER_PLANE = `${GCP_URL}/paper_plane.svg`
export const NEW_RIBBON = `${GCP_URL}/new-ribbon.svg`
export const NEW_INSURANCE_MC = `${GCP_URL}/buy-new-insurance.svg`
export const RENEW_PP_INSURANCE = `${GCP_URL}/extend-policy-from-partner.svg`
export const RENEW_OUTSIDE_INSURANCE = `${GCP_URL}/extend-policy-from-outside.svg`
export const DOCUMENT = `${GCP_URL}/document.svg`
export const FILE_GOLD = `${GCP_URL}/file-gold.svg`
export const PAY_LATER = `${GCP_URL}/pay-later.svg`
export const GOOGLE_DOC = `${GCP_URL}/google_docs.svg`
export const BENEFIT = `${GCP_URL}/benefit.svg`
export const HISTORY_GOLD = `${GCP_URL}/history.svg`
export const HEALTH_INSURANCE_GOLD = `${GCP_URL}/health_insurance.svg`
export const MONEY_BAGS = `${GCP_URL}/money_bags.svg`
export const TELEPHONE_BLACK = `${GCP_URL}/telephone.svg`
export const DOWNLOAD_GOLD = `${GCP_URL}/download_gold.svg`
export const PEAK_ICON = `${GCP_URL}/peak.svg`
export const ARROW_DOWN_WHITE = `${GCP_URL}/arrow-down-white.svg`
export const LOGIN_AS_AGENT = `${GCP_URL}/login-as-dependent.svg`
export const LOGIN_AGENT_GOLD = `${GCP_URL}/login-as-agent-gold.svg`
export const BLOCK_WITHDRAWAL_BALANCE = `${GCP_URL}/block-withdrawal-balance.svg`
export const BLOCK_POINTS = `${GCP_URL}/block-points.svg`
export const PAYMENT_METHOD_BLOCK = `${GCP_URL}/payment-method-block.svg`
export const PAPER_PLAN_V2 = `${GCP_URL}/paper-plane-v2.svg`
export const LOGOUT_AGENT_GOLD = `${GCP_URL}/logout-agent-gold.svg`
export const CONTRACT_GOLD = `${GCP_URL}/contract.svg`
export const ADDON_FREE_GOLD = `${GCP_URL}/addon-free.svg`
export const CAMERA_GREY = `${GCP_URL}/camera-grey.svg`
export const DOWNLOAD = `${GCP_URL}/download.svg`
export const BLACK_PLANE = `${GCP_URL}/black_plane.svg`
export const WHITE_DOWNLOAD = `${GCP_URL}/white_download.svg`
export const NEW_DOWNLOAD_ICON = `${GCP_URL}/new_download.svg`
export const NEW_ARROW_RIGHT = `${GCP_URL}/new_arrow%20right.svg`
export const BUY_PROPERTY_HOME = `${GCP_URL}/buy-property-home.svg`
export const SEND_GOLD_ICON = `${GCP_URL}/send-gold-icon.svg`
export const GENERAL_LIST_ICON = `${GCP_URL}/general-list-icon.svg`
export const TRASH_ICON_V2 = `${GCP_URL}/Trash.svg`
export const UPLOAD_GREY = `${GCP_URL}/upload-grey.svg`
export const ARROW_DOWN = 'https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png'

export const BRONZE_BADGE = `${GCP_URL}/bronze-color-star.svg`
export const SILVER_BADGE = `${GCP_URL}/silver-color-star.svg`
export const GOLD_BADGE = `${GCP_URL}/gold-color-star.svg`
export const PLATINUM_BADGE = `${GCP_URL}/platinum-color-star.svg`
export const ELEARNING = `${GCP_URL}/my_account_e_learning.svg`
export const ELEARNING_NEW_ICON = `${GCP_URL}/eLearning-icon.png`
export const ELEARNING_BACKGROUND = `${GCP_URL}/eLearning-background.svg`
export const HOME_NEW_ICON = `${GCP_URL}/home-new.png`
export const POLICIES_NEW_ICON = `${GCP_URL}/policies-new.png`
export const MY_ACCOUNT_NEW_ICON = `${GCP_URL}/my_account-new.png`
export const WALLET_NEW_ICON = `${GCP_URL}/wallet-new.png`
export const GENERAL_LOCK = `${GCP_URL}/general-lock.svg`
export const DOWN_ARROW_GOLD = `${GCP_URL}/down_arrow_gold.svg`

export const CHECK_GOLD = `${GCP_URL}/inputs_check_mark_gold.svg`
export const CALCULATOR_GOLD = `${GCP_URL}/Calculator.svg`
export const SHARE = `${GCP_URL}/share-icon.svg`
export const INPUT_CONTACT = `${GCP_URL}/input-contact.svg`
export const WHITE_DOC_ICON = `${GCP_URL}/white-doc-icon.svg`
export const DELETE = `${GCP_URL}/delete.svg`

// product icons
export const VEHICLE = `${GCP_URL}/vehicle.svg`
export const HOSPITAL = `${GCP_URL}/hospital.svg`
export const MOTOR_BIKE = `${GCP_URL}/motorbike.svg`
export const JIWA = `${GCP_URL}/jiwa.svg`
export const PROPERTY = `${GCP_URL}/property-logo.svg`

// provider icons
export const ADIRA_LOGO = 'https://storage.googleapis.com/pp_agent_mweb/provider/zurich-logo-v2.png'

// banner
export const SUN_LIFE_PLUS_BANNER = `${GCP_URL}/sun-life-plus-banner.jpg`
export const SUN_LIFE_LANDING = `${GCP_URL}/sun-life-landing-page.png`
export const PULAU_BALI_BANNER = `${GCP_URL}/pulau-bali-banner-agent.png`
export const LANGKAWI_AGENT_BANNER = `${GCP_URL}/langkawi-agent.png`
export const LANGKAWI_AGGREGATOR_BANNER = `${GCP_URL}/langkawi-aggregator.png`
export const REFERRAL_BONUS_BANNER = `${GCP_URL}/referral-bonus-v3.png` // referral-bonus-v2.png
export const REFERRAL_BONUS_BANNER_V4 = `${GCP_URL}/referral-bonus-v5.jpg`
export const VA_PAYMENT_METHOD_BANEER = `${GCP_URL}/campaign_virtual_account_banner.png`
export const CLAIMS_BANNER = `${GCP_URL}/hujan-bonus-artboard-banner.png`
export const CLAIM_REPORT = `${GCP_URL}/claim-report.png`
export const TAP_TRAVEL_BANNER = `${GCP_URL}/Tap%20Travel%20Launch.jpg`
export const TAP_TRAVEL_BANNER_DETAIL = `${GCP_URL}/Tap%20Travel%20Launch%20-%20details.jpg`
export const BALI_BANNER_DETAIL = `${GCP_URL}/bali_detail_banner.png`
export const BALI_BANNER_SHORT = `${GCP_URL}/bali_short_banner.png`
export const BANGKOK_BANNER_DETAIL = `${GCP_URL}/bangkok_detail_banner.png`
export const BANGKOK_BANNER_SHORT = `${GCP_URL}/bangkok_short_banner.png`
export const HK_BANNER_DETAIL = `${GCP_URL}/hk_detail_banner.png`
export const HK_BANNER_SHORT = `${GCP_URL}/hk_short_banner.png`
export const SOUTH_AFRICA_BANNER_DETAIL_UPDATE = `${GCP_URL}/south_africa_upadted_banner.jpg`
export const SOUTH_AFRICA_BANNER_DETAIL = `${GCP_URL}/Campaign%20Apr%202024%20-%20South%20Africa%20-%20Details.jpg`
export const SOUTH_AFRICA_BANNER_SHORT = `${GCP_URL}/south_africa_short_banner.jpg`
export const TURKIYE_BANNER_DETAIL_UPDATE = `${GCP_URL}/turkiye_updated_banner.jpg`
export const TURKIYE_BANNER_DETAIL = `${GCP_URL}/Campaign%20Apr%202024%20-%20Turkiye%20-%20details.jpg`
export const TURKIYE_BANNER_SHORT = `${GCP_URL}/turkiye_short_banner.jpg`
export const FAMILY_GROUP_BANNER = `${GCP_URL}/family_group_banner.jpg`

// banner details png
export const PULAU_BALI_DETAIL = `${GCP_URL}/pulau-bali-banner-detail.png`
export const LANGKAWI_AGGREGATOR_DETAIL = `${GCP_URL}/lankawi-aggregator-banner-detail.png`
export const LANGKAWI_AGENT_DETAIL = `${GCP_URL}/lankawi-agent-banner-detail.png`
export const REFERRAL_BONUS_DETAIL = `${GCP_URL}/referral-bonus-banner-detail_v3.png` // referral-bonus-banner-detail.png
export const REFERRAL_BONUS_DETAIL_V4 = `${GCP_URL}/referral-bonus-banner-detail_v8.jpg`
export const VA_PAYMENT_METHOD_DETAIL = `${GCP_URL}/campaign_virtual_account_details_v2.png`
export const CLAIMS_BANNER_DETAILS = `${GCP_URL}/hujan-bonus-banner-detail.png`

export const CAMPAIGN_AGENT_FINLANDIA_BANNER = `${GCP_URL}/campaign-agent-finlandia-banner.png`
export const CAMPAIGN_AGENT_FINLANDIA_DETAIL_BANNER = `${GCP_URL}/campaign-agent-final-banner-detail.png`
export const CAMPAIGN_AGENT_TURKEY_DETAIL_BANNER = `${GCP_URL}/campaign-agent-turkey-banner-details.png`
export const CAMPAIGN_AGENT_TURKEY_BANNER = `${GCP_URL}/campaign-agent-turkey-banner.png`
export const CAMPAIGN_AGENT_IPHONE_DETAIL_BANNER = `${GCP_URL}/campaign-agent-iPhone-details.png`
export const CAMPAIGN_AGENT_IPHONE_BANNER = `${GCP_URL}/campaign-agent-iPhone.png`
export const CAMPAIGN_AGENT_VOUCHER_DETAIL_BANNER = `${GCP_URL}/campaign-agent-voucher-details.png`
export const CAMPAIGN_AGENT_VOUCHER_BANNER = `${GCP_URL}/campaign-agent-voucher-banner.png`
export const CAMPAIGN_AGGREGATOR_BANNER = `${GCP_URL}/campaign-aggregator-finland-banner.png`
export const CAMPAIGN_AGGREGATOR_DETAIL_BANNER = `${GCP_URL}/campaign-aggregator-finland-detail.png`
export const CAMPAIGN_RAMADAN_BANNER = `${GCP_URL}/Campaign-ramadan-21.png`
export const CAMPAIGN_RAMADAN_DETAIL_BANNER = `${GCP_URL}/campaign-ramadan-detail-21.png`
export const CAMPAIGN_RAMADAN_DETAIL_BANNER_HOME = `${GCP_URL}/campaign-ramadan-details-home-v2.png`
export const RAMADAN_BONANZA_BANNER = `${GCP_URL}/ramadan-bonanza-banner.png`
export const RAMADAN_BONANZA_BANNER_DETAIL = `${GCP_URL}/ramadan-bonanza-details-v2.png`
export const RAMADAN_BONANZA_BANNER_AGGREGATOR_DETAIL = `${GCP_URL}/ramadan-bonanza-aggregator-details-v2.png`
export const RAMADAN_BONANZA_BANNER_HOME = `${GCP_URL}/ramadan-bonanza-home.png`
export const PROGRAM_REFERRAL_BANNER = `${GCP_URL}/program-referral-cover.png`
export const PROGRAM_REFERRAL_BANNER_DETAIL = `${GCP_URL}/program-referral-cover-details-v2.png`

export const ITALIA_MALTA_BANNER = `${GCP_URL}/agent_italia_malta.png`
export const ITALIA_BANNER = `${GCP_URL}/agent_italia.png`
export const IPHONE_BANNER = `${GCP_URL}/agent_iphone.png`
export const INDIA_BANNER = `${GCP_URL}/agent_India.png`
export const ITALIA_MALTA_BANNER_DETAIL = `${GCP_URL}/agent_text_italia_malta.png`
export const ITALIA_BANNER_DETAIL = `${GCP_URL}/agent_text_italia.png`
export const IPHONE_BANNER_DETAIL = `${GCP_URL}/agent_text_iphone.png`
export const INDIA_BANNER_DETAIL = `${GCP_URL}/agent_text_india.png`

export const FWD_CAMPAIGN_BANNER = `${GCP_URL}/fwd_camp_banner.png`
export const FWD_CAMPAIGN_BANNER_DETAIL = `${GCP_URL}/fwd_camp_banner_detail.png`

export const LOCATION_PIN = `${GCP_URL}/location_pin.png`

export const ONLINE_PAYMENT_BANNER = `${GCP_URL}/claim_test.png`
export const ONLINE_PAYMENT_BANNER_DETAIL = `${GCP_URL}/online_payment_detail.png`
export const FWD_BANNER = `${GCP_URL}/fwd_banner.png`
export const FWD_BANNER_DETAIL = `${GCP_URL}/fwd_detail.png`

export const UMUM_MEGA_BANNER = `${GCP_URL}/Campaign%202023%20-%203.jpg`
export const UMUM_MEGA_BANNER_DETAIL = `${GCP_URL}/Campaign%20July%202023%20-%20Mega.jpg`
export const STACO_BANNER = `${GCP_URL}/Campaign%202023%20-%201.jpg`
export const STACO_BANNER_DETAIL = `${GCP_URL}/Campaign%20July%202023%20-%20Staco.jpg`
export const AUS_BANNER = `${GCP_URL}/Campaign%20Dec%202023%20AUS%20img.jpg`
export const AUS_BANNER_DETAIL = `${GCP_URL}/Campaign%20Dec%202023%20AUS%20.jpg`
export const SINARMAS_BANNER = `${GCP_URL}/Campaign%20July%202023%20-%20Sinar%20Mas.jpg`
export const SINARMAS_BANNER_DETAIL = `${GCP_URL}/Campaign%20Dec%202023%20Sinar%20Mas.jpg`
export const NPWP_TO_NIK_BANNER_DETAIL = `${GCP_URL}/banner-detail-npwp-nik.jpg`
export const NPWP_TO_NIK_BANNER = `${GCP_URL}/banner-npwp-to-nik.png`
export const TERMS_AND_CONDITIONS_BANNER = `${GCP_URL}/ppw_short_banner.png`
export const TERMS_AND_CONDITIONS_BANNER_DETAIL = `${GCP_URL}/ppw_detail_banner.jpg`
export const EW_BANNER = `${GCP_URL}/ew_short_banner.jpg`
export const EW_BANNER_DETAIL = `${GCP_URL}/CampaignApr2024%20-Turkiye-detail.jpg`
export const SEPT_BANNER = `${GCP_URL}/New_Campaign_Sept.jpg`
export const SEPT_BANNER_DETAIL = `${GCP_URL}/New_Campaign_Sept%20_Details.jpg`
export const DEC_BANNER = `${GCP_URL}/Campaign%20Dec.jpg`
export const DEC_BANNER_DETAIL = `${GCP_URL}/Campaign%20Dec%20-%20Details.jpg`
export const JAN_BANNER = `${GCP_URL}/Campaign_Jan_2025.jpg`
export const JAN_BANNER_DETAIL = `${GCP_URL}/Campaign_Jan_2025_Detail.jpg`

export const SINARMAS_BANNER_V2 = `${GCP_URL}/Campaign%20Dec%202023%20Sinar%20Mas%20img.jpg`
export const SINARMAS_BANNER_DETAIL_V2 = `${GCP_URL}/Campaign%20Dec%202023%20Sinar%20Mas.jpg`
export const UMUM_MEGA_BANNER_V2 = `${GCP_URL}/Campaign%20Dec%202023%20-%20AUM%20-%201.jpg`
export const UMUM_MEGA_BANNER_DETAIL_V2 = `${GCP_URL}/Campaign%20Dec%202023%20-%20AUM%20-%202.jpg `
export const STACO_BANNER_V2 = `${GCP_URL}/Campaign%20Nov-Dec%202023%20-%20Staco%20-1.jpg `
export const STACO_BANNER_DETAIL_V2 = `${GCP_URL}/Campaign%20Nov-Dec%202023%20-%20Staco%20-2.jpg `
// referral earn banner
export const REFERRAL_EARN_V1 = `${GCP_URL}/referral-earn-v1.svg`

// pkg background img
export const PROPERTY_BG_IMG = `${GCP_URL}/property-detail-bg.svg`

// Building Guide Image
export const BUILDING_FRONT = `${GCP_URL}/front-look.jpg`
export const BUILDING_LEFT = `${GCP_URL}/left-look.jpg`
export const BUILDING_RIGHT = `${GCP_URL}/right-look.jpg`
export const BUILDING_INSIDE_1 = `${GCP_URL}/building-in-1.jpg`
export const BUILDING_INSIDE_2 = `${GCP_URL}/building-in-2.jpg`
export const BUILDING_INSIDE_3 = `${GCP_URL}/building-in-3.jpg`

// Ad banner page
export const REFERRAL_BONUS_AD_DETAIL = `${GCP_URL}/referral_bonus_mobile_ad.jpg`
export const REFERRAL_BONUS_AD_DETAIL_V2 = `${GCP_URL}/referral_bonus_mobile_ad_v5.png`
export const GREETINGS_NEW_YEAR = `${GCP_URL}/greetings-marcom-ny-2021.png`
export const SCHEDULE_CONTENT = `${GCP_URL}/pp-mitra-mlm-bonus.png`

export const PULAU_BALI_BANNER_DEC = `${GCP_URL}/pulau-bali-banner-agent-v2.png`
export const PULAU_BALI_DETAIL_DEC = `${GCP_URL}/pulau-bali-banner-detail-v2.png`
export const DOWNTIME_ICON = `${GCP_URL}/downtime.png`
export const COVID_SUPPORT = `${GCP_URL}/covid-support.png`

// Success Proof
export const SUCCESS_PROOF_IMG = `${GCP_URL}/success-proof-submit.svg`
export const NEW_SUCCESS_PROOF_IMG = `${GCP_URL}/mitra_successful.svg`
export const UNAUTHORIZED_ACCESS_PEOPLE = `${GCP_URL}/unauthorised-access-prople.svg`

// Welcome Page
export const CITY_LIFE = `${GCP_URL}/city-life.svg`
export const BENEFIT_3 = `${GCP_URL}/benefit-4.svg`
export const BENEFIT_2 = `${GCP_URL}/benefit-5.svg`
export const BENEFIT_1 = `${GCP_URL}/benefit-6.svg`
export const STEPS = `${GCP_URL}/steps.svg`
export const OPINIONS = `${GCP_URL}/opinions.svg`
export const OJK = `${GCP_URL}/ojk.svg`

// Not found
export const PACKAGE_NOT_FOUND = `${GCP_URL}/package_not_found.png`
export const INVOICE_NOT_FOUND = `${GCP_URL}/invoice_not_found.png`
// icons
export const CLAIM_ICON = `${GCP_URL}/claim-icon.svg`
export const RECEIPT_ICON = `${GCP_URL}/receipt-icon.svg`
export const RENEWAL_POLICY_ICON = `${GCP_URL}/renewal-policy-icon.svg`
export const ADDRESS_ICON = `${GCP_URL}/mailbox.svg`
export const SHARE_GREY = `${GCP_URL}/share-grey.svg`
export const DOWNLOAD_GREY = `${GCP_URL}/download-grey.svg`

// excel sheet for fleet as sample
export const FLEET_SAMPLE_EXCEL_SHEET = `${GCP_URL}/Fleet-excel-Format-sample.xlsx`

// sekoci Replay pdf file
export const SEKOCI_RIPLAY_PERSONAL_PDF = `${GCP_URL}/RIPLAY_Personal_Mikro_SEKOCI.pdf`
export const SEKOCI_RIPLAY_TERMS_PDF = `${GCP_URL}/RIPLAY-Sekoci-v1.10.pdf`

// Agent 2.0 icons
export const CALL_HELP = `${GCP_URL}/call_for_help.svg`
export const NOTIFICATION_NEW = `${GCP_URL}/icon_notification.svg`
export const CLOSE_GREY = `${GCP_URL}/close_new_grey.png`
export const WHATS_APP_NEW = `${GCP_URL}/icon_whatsapp.png`
export const CALL_NEW = `${GCP_URL}/icon_call.png`
export const CALL_SVG = `${GCP_URL}/call_svg.svg`
export const EMAIL_SVG = `${GCP_URL}/email_svg.svg`
export const EMAIL_SVG_BOLD = `${GCP_URL}/EMAIL_ICON_BOLD.svg`
export const WHATSAPP_SVG = `${GCP_URL}/whatsapp_svg.svg`
export const COVID_NEW = `${GCP_URL}/covid_new_icon.png`
export const CARGO_NEW = `${GCP_URL}/cargo_new_icon.png`
export const HEALTH_NEW = `${GCP_URL}/health_new_icon.png`
export const LIFE_NEW = `${GCP_URL}/life_new_icon.png`
export const HEALTH_MICRO_NEW = `${GCP_URL}/health_micro_new_icon.png`
export const MOTOR_VEHICLE_NEW = `${GCP_URL}/motor_vehicle_new_icon.png`
export const MOTOR_CYCLE_NEW = `${GCP_URL}/motorcycle_new_icon.png`
export const PROPERTY_NEW = `${GCP_URL}/property_new_icon.png`
export const AUTO_SHOP_NEW = `${GCP_URL}/icon_autoshop.svg`
export const CLAIM_NEW = `${GCP_URL}/icon_claim_new.svg`
export const POLICY_EXTEND = `${GCP_URL}/icon_policy_extend.svg`
export const RECEIPT = `${GCP_URL}/icon_receipt.svg`
export const INFORMATION_NEW = `${GCP_URL}/icon_information.svg`
export const BACK_NEW_ICON = `${GCP_URL}/back_new_icon.png`
export const SAVE_ICON = `${GCP_URL}/save_icon.png`
export const CLOSE_BLACK_ICON = `${GCP_URL}/close_black_icon.png`
export const MINI_INFO = `${GCP_URL}/icon_mini_info.svg`
export const EDIT_ICON = `${GCP_URL}/icon_edit.svg`

export const BUS_ICON = `${GCP_URL}/icon_bus.svg`
export const GOLD_ICON_RIGHT_ARROW = `${GCP_URL}/icon_path_right.png `
export const PASSENGER_CAR_ICON = `${GCP_URL}/icon_passenger_car.svg`
export const TRUCK_ICON = `${GCP_URL}/icon_pickup_truck.svg`
export const SELECTION_ARROW = `${GCP_URL}/icon_arrow_selection.svg`
export const ITEM_NOT_FOUND = `${GCP_URL}/items_not_found.png`
export const ICON_ADD = `${GCP_URL}/icon_add.png`
export const ICON_CANCELLED = `${GCP_URL}/icon_cancelled.png`
export const ICON_SENT = `${GCP_URL}/icon_sent.png`
export const ICON_SHIELD = `${GCP_URL}/icon_shield.png`
export const ICON_TIMER = `${GCP_URL}/icon_timer.png`
export const FILTER_ICON = `${GCP_URL}/icon_filters.png`
export const PKG_OPTIONS = `${GCP_URL}/icon_package_options.png`
export const RULES = `${GCP_URL}/Rules.svg`
export const BENEFITS = `${GCP_URL}/Benefits.svg`
export const SHIELD_ICON = `${GCP_URL}/icon_shield.png`
export const LINK_ICON = `${GCP_URL}/icon_link.svg`
export const PDF_ICON = `${GCP_URL}/icon_pdf.svg`
export const HOME_ICON = `${GCP_URL}/icon_home.svg`
export const UMBRELLA_ICON = `${GCP_URL}/new_policy_icon.svg`
export const SENT_ICON = `${GCP_URL}/Sent.svg`
export const CAR_ICON = `${GCP_URL}/icon_car.svg`
export const UPLOAD_ICON = `${GCP_URL}/icon_upload.svg`
export const TRANSMISSION_AUTO_ICON = `${GCP_URL}/icon_transmission_auto.svg`
export const TRANSMISSION_MANUAL_ICON = `${GCP_URL}/icon_transmission_manual.svg`
export const CHECKLIST_ICON = `${GCP_URL}/icon_checklist.svg`
export const CUSTOMER_ICON_NEW = `${GCP_URL}/icon_customer.svg`
export const OBJECT_DETAIL_ICON = `${GCP_URL}/icon_object_details.svg`
export const PKG_DETAIL_ICON = `${GCP_URL}/icon_package_details.svg`
export const MINI_EDIT_ICON = `${GCP_URL}/icon_mini_edit.svg`
export const STORY_SET_REMINDER = `${GCP_URL}/illustration_storyset_reminder.svg`
export const COPY_ICON = `${GCP_URL}/icon_copy.svg`
export const ARROW_RIGHT_ICON = `${GCP_URL}/icon_arrow_right.svg`
export const GREEN_CHECK = `${GCP_URL}/icon_mini_check.png`
export const GREEN_CHECK_V2 = `${GCP_URL}/icon_mini_check_V2.png`
export const MINI_HELP_ICON = `${GCP_URL}/icon_mini_help.png`
export const GOLD_UPLOAD_ICON = `${GCP_URL}/gold_icon_upload.png`
export const TRASH_ICON = `${GCP_URL}/icon_trash.png`
export const BOTTOM_DOWN_ICON = `${GCP_URL}/icon_bottom_down.png`
export const POLICY_OVER_VIEW_ICON = `${GCP_URL}/icon_policy_overview.png`
export const OFFLINE_PRODUCT_ICON = `${GCP_URL}/offline_product.svg`
export const CAMERA_BLACK = `${GCP_URL}/icon_camera.png`
export const CARET_GOLD = `${GOOGLE_STORAGE_BASE_URL}/caret-down-1.png`
export const ARROW_RIGHT_BLUE = `${GCP_URL}/icon_arrow_right_blue.svg`
export const SETTING = `${GCP_URL}/icon_settings.png`
export const PERFORMANCE = `${GCP_URL}/icon_performance.png`
export const DOWNLINE = `${GCP_URL}/icon_downline.png`
export const REFERRAL = `${GCP_URL}/icon_referral.png`
export const HELP_ICON = `${GCP_URL}/icon_help.png`
export const CUSTOMER_SUPPORT = `${GCP_URL}/icon_customer_support.png`
export const CAR_CUSTOM = `${GCP_URL}/icon_car_custom.png`
export const KEYS = `${GCP_URL}/icon_login_as_agent.png`
export const TIER_B = `${GCP_URL}/tier_B_Badge_v1.svg`
export const TIER_A = `${GCP_URL}/tier_A_badge.svg`
export const ACCOUNT_BANK = `${GCP_URL}/account_balance.png`

// Guide imgs
export const STNK_GUIDE = `${GCP_URL}/stnk_skpd.png`
export const CAR_FRONT_GUIDE = `${GCP_URL}/car-front-guide.png`
export const CAR_BACK_GUIDE = `${GCP_URL}/car-back-guide.png`
export const CAR_LEFT_GUIDE = `${GCP_URL}/car-left-guide.png`
export const CAR_RIGHT_GUIDE = `${GCP_URL}/car-right-guide.png`
export const CAR_DASHBOARD_GUIDE = `${GCP_URL}/car-dashboard-guide.png`
export const ICON_SAVED = `${GCP_URL}/icon_saved.png`
export const ICON_SHIELD_FILLED = `${GCP_URL}/icon_shield_filled.png`
export const INSURANCE_PROVIDER = `${GCP_URL}/icon_insurance_provider.png`
export const DELETE_WHITE = `${GCP_URL}/icon_mini_delete.png`

// information send
export const INFORMATION_SENT = `${GCP_URL}/illustration_storyset_sent.png`
export const PAYMENT_FAILED_IMG = `${GCP_URL}/payment_failed.png`
export const PAYMENT_PENDING_IMG = `${GCP_URL}/payment_pending.png`
export const PAYMENT_NEW_PENDING_IMG = `${GCP_URL}/new_pending_img.png`

export const OJK_PP_WHITE = `${GCP_URL}/White_Logo.png`
export const OJK_PP_BLACK = `${GCP_URL}/Black_Logo.png`
export const VIEW_HIDDEN = `${GCP_URL}/View_Off.svg`

export const LOGIN_IMG = `${GOOGLE_STORAGE_BASE_URL}/Login_Img.png`
export const LOGIN_IMG_SVG = `${GOOGLE_STORAGE_BASE_URL}/Login_Img_New.svg`
export const PP_BLACK_LOGO = `${GOOGLE_STORAGE_BASE_URL}/PasarPolis_Mitra_Black.png`
export const PP_BLACK_LOGO_SVG = `${GOOGLE_STORAGE_BASE_URL}/PasarPolis_Mitra_Black_New.svg`
export const SMARTPHONE = `${GOOGLE_STORAGE_BASE_URL}/Smartphone.png`
export const SMS = `${GOOGLE_STORAGE_BASE_URL}/sms.png`
export const PIN_NEW = `${GOOGLE_STORAGE_BASE_URL}/pin_new.svg`
// // Killian
// export const DOWN_ARROW_GOLD = `${GCP_URL}/down_arrow_gold.svg`
